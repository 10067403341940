import * as React from 'react';
import {AioPage, Button, ButtonContainer, FormGroup, Logo, Spacer} from '@autopay.io/style';
import {Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {FormikButton, FormikForm, FormikInputField} from '@autopay.io/style/lib/formik';
import {firebaseAuthService} from '@autopay.io/common/lib/auth';
import {ForgotPasswordFirebaseResponse, ForgotPasswordForm} from '../types';
import {routes} from '../../../routes';
import {browserHistory} from '../../../app';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please enter a valid e-mail address. Example@email.com.'),
});

export const ForgotPassword = () => {
    const [firebaseResponse, setFirebaseResponse] = React.useState<ForgotPasswordFirebaseResponse | null>(null);
    const initialValues = {email: ''};

    function submit(form: ForgotPasswordForm, formik: FormikHelpers<ForgotPasswordForm>) {
        if (firebaseAuthService.firebaseInstance.auth) {
            formik.setSubmitting(true);
            firebaseAuthService.firebaseInstance.auth().sendPasswordResetEmail(form.email)
                .then(() => {
                    setFirebaseResponse({
                        type: 'success',
                        message: 'If there is an account associated with this e-mail address, we will send you an e-mail with a link to reset your password immediately.',
                    });
                })
                .catch((error) => {
                    if (error.code === 'auth/user-not-found') {
                        setFirebaseResponse({type: 'error', message: 'We could not find a user with the entered e-mail.'});
                    } else {
                        setFirebaseResponse({
                            type: 'error',
                            message: 'Something went wrong when trying to send the password reset e-mail. Please try again.',
                        });
                    }
                })
                .finally(() => formik.setSubmitting(false));
        }
    }

    return (
        <AioPage type="blank-page">
            <div className="landing-page-body">
                <div className="landing-page-left">
                    <div className="aio-mobile-menu">
                        <Logo />
                    </div>
                    <div className="aio-header">
                        <div className="aio-header-left">
                            <Logo color="blue" />
                        </div>
                    </div>
                    <div className="illustration" />
                    <div className="footer" />
                </div>
                <div className="form-container">
                    {firebaseResponse ? (
                        <>
                            <h1>Reset password</h1>
                            <Spacer size="sm" />
                            <p>{firebaseResponse.message}</p>
                            <Spacer size="md" />
                            <ButtonContainer alignment="left">
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => browserHistory.push(`${routes.LOGIN}`)}
                                >
                                    Back to login
                                </Button>
                            </ButtonContainer>
                            <Spacer size="md" />
                        </>
                    ) : (
                        <>
                            <h1>Forgot your password?</h1>
                            <Spacer size="sm" />
                            <p>
                                Please enter your e-mail address to start the process of
                                resetting your password
                            </p>
                            <Spacer size="sm" />
                            <Formik
                                key="forgot-formik"
                                onSubmit={submit}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                            >
                                <FormikForm>
                                    <FormGroup>
                                        <FormikInputField
                                            name="email"
                                            labelText="E-mail address"
                                        />
                                    </FormGroup>
                                    <Spacer size="md" />
                                    <ButtonContainer alignment="left">
                                        <FormikButton type="submit">
                                            Send e-mail
                                        </FormikButton>
                                        <Button
                                            color="secondary"
                                            type="button"
                                            onClick={() => browserHistory.push(`${routes.ROOT}`)}
                                        >
                                            Cancel
                                        </Button>
                                    </ButtonContainer>
                                    <Spacer size="md" />
                                </FormikForm>
                            </Formik>
                        </>
                    )}
                </div>
            </div>
        </AioPage>
    );
};
