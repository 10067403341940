import * as React from 'react';
import {useEffect, useState} from 'react';
import './bookings.less';
import BookingList from './scenes/bookingList/bookingList';
import {Message, Spinner} from '@autopay.io/style';
import {Booking, BookingAvailabilityState, BookingViewState, Permit} from './types';
import CreateBooking from './scenes/createBooking/createBooking';
import EditBooking from './scenes/editBooking/editBooking';
import {fetchPermits, getAvailability} from '../../services/http';
import {getPermitIdentifier} from '../../utils';

export const Bookings = () => {
    const [permits, setPermits] = React.useState<Permit[]>([]);
    const [viewState, setViewState] = React.useState<BookingViewState>({type: 'LOADING'});
    const [availability, setAvailability] = useState<BookingAvailabilityState>(null);

    useEffect(() => {
        localStorage.removeItem('statusFeaturePopupShown'); // todo - remove in the future
        const selectedPermitClientId = localStorage.getItem('clientId');
        getPermits(undefined, selectedPermitClientId);
    }, []);

    function getPermits(selectedPermit?: Permit, savedPermitClientId?: string | null) {
        setViewState({type: 'LOADING'});

        return fetchPermits().then((res) => {
            if (res.type === 'SUCCESS') {
                setPermits(res.data);
                setViewState({type: 'LIST', permit: selectedPermit ?? res.data.find((p) => p.clientId === savedPermitClientId)});
            } else {
                navigateToErrorPage();
            }
        });
    }

    function navigateToCreatePage(permit: Permit) {
        setViewState({
            type: 'CREATE',
            permit,
        });
    }

    function navigateToEditPage(booking: Booking) {
        const bookingProduct = permits.find((permit) => getPermitIdentifier(permit) === `${booking.permitDefinitionId}_${booking.tenantId}`);

        if (!bookingProduct) {
            navigateToErrorPage();
            return;
        }

        setViewState({
            type: 'EDIT',
            booking,
            permit: bookingProduct,
        });
    }

    function navigateToErrorPage() {
        setViewState({type: 'ERROR'});
    }

    const fetchAvailability = (date: string, clientId: string) => {
        setAvailability(null);
        getAvailability(clientId, date).then((r) => {
            if (r.type === 'SUCCESS') {
                setAvailability(r.data);
            } else {
                setAvailability('ERROR');
            }
        });
    };

    if (viewState.type === 'LOADING') {
        return <Spinner size="md" />;
    } else if (viewState.type === 'ERROR') {
        return (
            <Message
                type="error"
                title="Oops! Something went wrong"
                message="There was a problem communicating with the server. Please try again later."
            />
        );
    } else if (viewState.type === 'LIST') {
        return (
            <>
                <BookingList
                    permits={permits}
                    previousPermit={viewState.permit}
                    bookingsAvailability={availability}
                    fetchAvailability={fetchAvailability}
                    onCreateClick={navigateToCreatePage}
                    onEditClick={navigateToEditPage}
                    onError={navigateToErrorPage}
                />
            </>
        );
    } else if (viewState.type === 'CREATE') {
        return (
            <CreateBooking
                goBack={getPermits}
                permit={viewState.permit}
                bookingsAvailability={availability}
                fetchAvailability={fetchAvailability}
            />
        );
    } else if (viewState.type === 'EDIT') {
        return (
            <EditBooking
                permit={viewState.permit}
                goBack={getPermits}
                booking={viewState.booking}
                bookingsAvailability={availability}
                fetchAvailability={fetchAvailability}
                onError={navigateToErrorPage}
            />
        );
    }
};
