import * as React from 'react';
import * as Yup from 'yup';
import {Formik, FormikHelpers} from 'formik';
import {AioPage, FormGroup, Link, Logo, Message, Spacer} from '@autopay.io/style';
import {LoginAuthResponse, LoginForm} from '../types';
import {routes} from '../../../routes';
import {getLoginErrorMessage, loginWithEmailAndPassword} from '../auth';
import {FormikButton, FormikForm, FormikInputField} from '@autopay.io/style/lib/formik';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please enter a valid e-mail address. Example@email.com.'),
    password: Yup.string()
        .required(() => 'Please enter your password.'),
});

export const Login = () => {
    const [authResponse, setAuthResponse] = React.useState<LoginAuthResponse | null>(null);

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('passwordReset') === '1') {
            setAuthResponse({type: 'success', message: 'Your password has been set!'});
        }
    }, [location.search]);

    const submit = (form: LoginForm, formik: FormikHelpers<LoginForm>) => {
        formik.setSubmitting(true);

        loginWithEmailAndPassword(form.email, form.password).then(() => window.location.href = routes.BOOKINGS)
            .catch((error) => {
                formik.setSubmitting(false);
                setAuthResponse({type: 'error', message: getLoginErrorMessage(error)});
            });
    };

    return (
        <AioPage type="blank-page">
            <div className="landing-page-body">
                <div className="landing-page-left">
                    <div className="aio-mobile-menu">
                        <Logo />
                    </div>
                    <div className="aio-header">
                        <div className="aio-header-left">
                            <Logo color="blue" />
                        </div>
                    </div>
                    <div className="illustration" />
                    <div className="footer" />
                </div>
                <div className="form-container">
                    <h1>Log in to Autopay Booking</h1>
                    <Spacer size="sm" />
                    {authResponse && (
                        <>
                            <Message type={authResponse.type} message={authResponse.message} />
                            <Spacer size="xs" />
                        </>
                    )}
                    <Formik
                        key="formik"
                        onSubmit={submit}
                        initialValues={{email: '', password: ''}}
                        validationSchema={validationSchema}
                    >
                        <FormikForm>
                            <FormGroup>
                                <FormikInputField
                                    type="email"
                                    name="email"
                                    labelText="E-mail address"
                                />
                            </FormGroup>
                            <Spacer size="xs" />
                            <FormGroup>
                                <FormikInputField
                                    type="password"
                                    name="password"
                                    labelText="Password"
                                    helperText="The password must be at least 8 characters long."
                                />
                            </FormGroup>
                            <Spacer size="md" />
                            <FormikButton type="submit">Log in</FormikButton>
                            <Spacer size="md" />
                            <Link type="external" linkType="inline-text" url={`${routes.RESET_PASSWORD}`}>Forgot your password?</Link>
                        </FormikForm>
                    </Formik>
                </div>
            </div>
        </AioPage>
    );
};
