import * as React from 'react';
import {useEffect} from 'react';
import {AioPage, Button, ButtonContainer, FormGroup, Link, Logo, Message, Spacer} from '@autopay.io/style';
import {FormikButton, FormikForm, FormikInputField} from '@autopay.io/style/lib/formik';
import {Formik, FormikHelpers} from 'formik';
import {firebaseAuthService} from '@autopay.io/common/lib/auth';
import {SetPasswordAuthResponse, SetPasswordForm} from '../types';
import * as Yup from 'yup';
import {passwordConfirmationErrorMessage} from '../auth';
import {routes} from '../../../routes';
import {browserHistory} from '../../../app';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required(() => 'Please enter your password.')
        .min(8, 'The password must be at least 8 characters long.'),
    confirmPassword: Yup.string()
        .required(() => 'Please enter your password.')
        .min(8, 'The password must be at least 8 characters long.'),
});

export const SetPassword = () => {
    const [authResponse, setAuthResponse] = React.useState<SetPasswordAuthResponse | null>(null);
    const [initializeError, setInitializeError] = React.useState<SetPasswordAuthResponse | null>(null);
    const [actionCode, setActionCode] = React.useState<string>('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const oobCode = params.get('oobCode')!;
        setActionCode(oobCode);

        firebaseAuthService.addInitializationListener(() => {
            if (firebaseAuthService.firebaseInstance.auth) {
                firebaseAuthService.firebaseInstance.auth().verifyPasswordResetCode(oobCode)
                    .catch((error) => {
                        setInitializeError({type: 'error', message: passwordConfirmationErrorMessage(error)});
                    });
            }
        });
    }, []);

    const renderFirebaseError = () => {
        if (initializeError && initializeError.message === 'The password reset was requested more than 1 hour ago') {
            return (
                <>
                    <Message type={initializeError.type} message={initializeError.message} />
                    <Spacer size="sm" />
                    <Link type="external" linkType="inline-text" url={`${routes.RESET_PASSWORD}`}>Request a new email with password reset link</Link>
                </>
            );
        } else if (initializeError) {
            return (
                <>
                    <Message type={initializeError.type} message={initializeError.message} />
                    <Spacer size="sm" />
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => browserHistory.push(`${routes.LOGIN}`)}
                    >
                        Back to login
                    </Button>
                </>
            );
        } else {
            return '';
        }
    };

    function submit(form: SetPasswordForm, formik: FormikHelpers<SetPasswordForm>) {
        formik.setSubmitting(true);

        if (form.password !== form.confirmPassword) {
            setAuthResponse({type: 'error', message: 'Please make sure that passwords match.'});
            formik.setSubmitting(false);
            return;
        }

        if (firebaseAuthService.firebaseInstance.auth && actionCode) {
            firebaseAuthService.firebaseInstance.auth().confirmPasswordReset(actionCode, form.password)
                .then(() => {
                    browserHistory.push(`${routes.LOGIN}?passwordReset=1`);
                })
                .catch((error) => {
                    formik.setSubmitting(false);
                    setAuthResponse({type: 'error', message: passwordConfirmationErrorMessage(error)});
                });
        }
    }

    return (
        <AioPage type="blank-page">
            <div className="landing-page-body">
                <div className="landing-page-left">
                    <div className="aio-mobile-menu">
                        <Logo />
                    </div>
                    <div className="aio-header">
                        <div className="aio-header-left">
                            <Logo color="blue" />
                        </div>
                    </div>
                    <div className="illustration" />
                    <div className="footer" />
                </div>
                <div className="form-container">
                    <h1>Set password</h1>
                    <Spacer size="sm" />
                    {initializeError ? renderFirebaseError() :
                        (
                            <>
                                {authResponse && (
                                    <>
                                        <Message type={authResponse.type} message={authResponse.message} />
                                        <Spacer size="sm" />
                                    </>
                                )}
                                <Formik
                                    onSubmit={submit}
                                    initialValues={{password: '', confirmPassword: ''}}
                                    validationSchema={validationSchema}
                                >
                                    <FormikForm>
                                        <FormGroup>
                                            <FormikInputField
                                                name="password"
                                                type="password"
                                                labelText="Password"
                                                helperText="The password must be at least 8 characters long."
                                            />
                                            <Spacer size="xs" />
                                            <FormikInputField
                                                name="confirmPassword"
                                                type="password"
                                                labelText="Confirm password"
                                            />
                                        </FormGroup>
                                        <Spacer size="md" />
                                        <ButtonContainer alignment="left">
                                            <FormikButton type="submit">Save</FormikButton>
                                        </ButtonContainer>
                                    </FormikForm>
                                </Formik>
                            </>
                        )}
                </div>
            </div>
        </AioPage>
    );
};
