import * as React from 'react';
import {Button, Icon, IconWithLabel, Spinner} from '@autopay.io/style';
import {BookingAvailabilityState} from '../types';
import {formatDate} from '../../../utils';

interface AvailabilityProps {
    bookingsAvailability: BookingAvailabilityState;
    showRefresh: boolean;
    refreshAvailability?: () => void;
}

const Availability = ({bookingsAvailability, showRefresh, refreshAvailability}: AvailabilityProps) => {
    const bookingsAvailableText = (availableToTime?: string) => {
        return <span>You can create bookings that {availableToTime ? <span>end the latest <b>{formatDate(availableToTime)}</b></span> : 'start now'}</span>;
    };

    const noBookingsAvailableText = (startTime?: string, endTime?: string) => {
        return (
            <span>
                <b>The limit for bookings is reached</b>
                <br />
                {endTime ? (
                    <>Next booking slot available during period: <b>{formatDate(startTime)} - {formatDate(endTime)}</b></>
                ) : (
                    <>Next available booking from: <b>{formatDate(startTime)}</b></>
                )}
            </span>
        );
    };

    if (!bookingsAvailability) {
        return <Spinner size="sm" delay="none" />;
    }

    if (bookingsAvailability === 'ERROR') {
        return (
            <div className="availability-group">
                <IconWithLabel icon="excl_red" label="Ops, we couldn't get the booking availability" />
            </div>
        );
    }

    return (
        <div className="availability-group">
            <Icon icon={bookingsAvailability.available ? 'check_green' : 'cross_red'} />
            {bookingsAvailability.available ?
                bookingsAvailableText(bookingsAvailability.validTo) :
                noBookingsAvailableText(bookingsAvailability.validFrom, bookingsAvailability.validTo)}
            {showRefresh && refreshAvailability &&
            <Button type="button" color="primary" size="sm" onClick={() => refreshAvailability()}>Refresh</Button>}
        </div>
    );
};

export default Availability;
